/** @jsx jsx */
import { jsx } from "theme-ui"
import Header from "./header"
import "../assets/scss/style.scss"


const Layout = ({children, className}) => {
  return (
    <div sx={layoutStyles.colorsMode}>
      <Header/>
      <main className={"container " + className}>
        {children}
      </main>
    </div>
  )
}

export default Layout

const layoutStyles = {
  colorsMode : {
    bg:"background",
  },
}