/** @jsx jsx */
import { jsx } from "theme-ui"
import { useColorMode } from 'theme-ui'
import { FiMoon } from "react-icons/fi";
import { FiSun } from "react-icons/fi";

const Colors = (props) => {
const [colorMode, setColorMode] = useColorMode()

  return(
    <div sx={themeStyles.modeOption}>
      <button
        onClick={e => {
          setColorMode(colorMode === 'default' ? 'dark' : 'default')
        }}>
          <div sx={themeStyles.modeText}>
            {colorMode === 'default' ? 'Dark' : 'Light'}
          </div>
          <div>
            {colorMode === 'default' ? <FiMoon/> : <FiSun/> }
          </div>
      </button>
    </div>
  )
}
export default Colors

const themeStyles = {
  modeOption : {
    "button": {
      fontSize: "25px",
      bg:"transparent",
      border:"none",
      display:"flex",
      alignItems:"center",
      color:"#fff",
      cursor:"pointer",
      mt:"5px",
      p: "0 20px 0 0",
      "&:hover":{
        color:"orange",
      }
    },

  },
  modeText : {
    fontSize: "16px",
    display:["block", "block", "block", "none"],
    pr:"10px",
  }
}