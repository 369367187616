/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import logo from "../util/menu.json"

import LogoSVG from "../../static/assets/logo.svg"
import LogoSpin from "../../static/assets/logo-spin.png"

const title = logo.logoText ? logo.logoText : ""

const Logo = () => (
  <div className="site-logo">
    <Link to="/">
      <img src={LogoSVG} alt={title + " - Logo"} />
      <img 
        className="spinner" 
        src={LogoSpin} 
        alt={title + " - Logo Spinner"} 
        sx={{
          bottom: ['2px', '3px']
        }}
      />
      <span className="hidden">{title}</span>
    </Link>
  </div>
)

export default Logo