/** @jsx jsx */
import { jsx } from "theme-ui"
import Logo from "./logo"
import Theme from './theme'

const Header = () => (
  <header sx={headerStyles.siteHeader}>
    <Logo/>
    <div sx={headerStyles.theme}>
    <Theme/>
    </div>

  </header>
)

export default Header

const headerStyles = {
  siteHeader : {  
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    bg: "headerBg",
    p: "20px"
  },
  theme:{
    display:["none", "none", "none", "flex"],
  }
}